.Header {
    height: 8vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
}
.Header-main {
     height: 5vh;
     width: 100%;

     display: flex;
     flex-direction: row;
     justify-content: space-between;
     background-color: black;
 }
.Header-button {
    height: 5vh;
    line-height:5vh;
    align-content: center;
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: dimgrey;
    font-weight: bold;
    font-size: 2vh;
    text-decoration: none;
    color: white;
}
.Header-title {
    color: bisque;
    font-weight: bold;
    font-size: 4vh;
    align-content: center;
}
.Header-logo {
    width: initial;
    height: 5vh;
}

.Header-progress {
    height: 3vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.Header-progress > .bar {
    height: 3vh;
    width: 10%;
    background-color: #ED1314;
    animation: 1s linear 0s infinite alternate move;
}
.Header-progress > .full {
    height: 3vh;
    width: 100%;
    background-color: #ED1314;
}
.Header-progress > .error {
    color:bisque;
    padding-right: 1vw;
    padding-left: 1vw;
    animation: shake 1s ease-in;
}
@keyframes move { from { margin-left:-10%; } to { margin-left:100%; }  }

@keyframes shake {
    0%,
    70%,100% {
        transform: translate(0);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translate(2vw);
    }
    10%,
    30%,
    50%{
        transform: translate(-2vw);
    }
}
