.day.prev, .day.next {
    background-color: #606060;
    color: white;
    font-weight: bold;
    text-decoration-line: initial;
    margin: .3vh;
    padding: .5vh;
}
.day.prev:before {
    content: '\25B2  ';
}
.day.next:after {
    content: ' \25BC';
}