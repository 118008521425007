.Login {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Login-logo {
    width: 30vw;
    pointer-events: none;
}

.Login-header {
    margin: 4vmin;
}

.Login-title {
    margin: 4vmin;
    color: #ED1314;
    font-size: calc(20px + 4vmin);
}

.Login-error {
    color: #ED1314;
    animation: shake 1s ease-in;
}

.Login-form {

}

.Login-form label {
    margin: 1vmin;
}

.Login-form input {
    margin: 1vmin;
}

.Login-link {
    color: #61dafb;
}

@keyframes shake {
    0%,
    70%,100% {
        transform: translate(0);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translate(2vw);
    }
    10%,
    30%,
    50%{
        transform: translate(-2vw);
    }
}
